<template>
  <el-col :span="24" style="padding-left: 16px">
    <span style="float:left; font-size: 15px">{{ $t('Поиск пользователя') }}</span>
    <el-input type="text" v-model="search"></el-input>
    <el-form v-if="form?.users?.length" ref="form" :model="form" :rules="rules" @submit.prevent="saveData">
      <el-auto-resizer>
        <el-table
          :data="form.users"
          style="width: 100%"
          :height="height"
          size="medium"
          fixed
        >
          <el-table-column
            width="190"
            prop="username"
            :label="$t('Имя пользователя')"
            class-name="element-top"
          >
            <template #default="scope">
                    <span class="cell-custom">
                        {{ scope.row.username }}
                    </span>
            </template>
          </el-table-column>

          <el-table-column
            min-width="210px"
            :label="$t('Роли')"
            class-name="element-top"
          >
            <template #default="scope">
              <el-form-item
                v-if="scope && scope.$index >= 0 && scope.row.isEdit"
                :prop="'users.' + scope.$index + '.roles'"
                :rules="rules.roles"
                :ref="'users.' + scope.$index + '.roles'"
              >
                <el-select v-model="scope.row.roles" multiple clearable :placeholder="$t('Выберите роль')">
                  <el-option
                    v-for="role in allRoles"
                    :key="role"
                    :label="role"
                    :value="role"
                  >
                    {{ role }}
                  </el-option>
                </el-select>
              </el-form-item>
              <span v-else class="cell-custom">
                        {{ scope.row.roles.join(', ') }}
                    </span>
            </template>
          </el-table-column>

          <el-table-column min-width="210px" :label="$t('Почта')" class-name="element-top">
            <template #default="scope">
              <el-form-item
                v-if="scope && scope.$index >= 0 && scope.row.isEdit"
                :prop="'users.' + scope.$index + '.email'"
                :rules="rules.email"
                :ref="'users.' + scope.$index + '.email'"
              >
                <el-input v-model="scope.row.email" clearable :placeholder="$t('Введите email')"></el-input>
              </el-form-item>
              <span v-else class="cell-custom">
                    {{ scope.row.email }}
                </span>
            </template>
          </el-table-column>

          <el-table-column
            width="190"
            prop="password"
            :label="$t('Пароль')"
            class-name="element-top"
          >
            <template #default="scope">
              <span class="cell-custom">{{ scope.row.password }}</span>
            </template>
          </el-table-column>

          <el-table-column
            width="210"
            :label="$t('Страна')"
            class-name="element-top"
          >
            <template #default="scope">
              <el-form-item
                v-if="scope && scope.$index >= 0 && scope.row.isEdit"
                :prop="'users.' + scope.$index + '.country'"
                :rules="rules.country"
                :ref="'users.' + scope.$index + '.country'"
              >
                <el-select v-model="scope.row.country" clearable :placeholder="$t('Выберите страну')">
                  <el-option
                    v-for="country in countryOptions"
                    :key="country.label"
                    :label="country.label"
                    :value="country.value"
                  />
                </el-select>
              </el-form-item>
              <span v-else>{{ scope.row.country }}</span>
            </template>
          </el-table-column>

          <el-table-column
            width="210"
            :label="$t('Язык')"
            class-name="element-top"
          >
            <template #default="scope">
              <el-form-item
                v-if="scope && scope.$index >= 0 && scope.row.isEdit"
                :prop="'users.' + scope.$index + '.lang'"
                :rules="rules.lang"
                :ref="'users.' + scope.$index + '.lang'"
              >
                <el-select v-model="scope.row.lang" :placeholder="$t('Выберите язык')">
                  <el-option
                    v-for="language in langOptions"
                    :key="language.label"
                    :label="language.label"
                    :value="language.value"
                  />
                </el-select>
              </el-form-item>
              <span v-else>{{ scope.row.lang }}</span>
            </template>
          </el-table-column>

          <el-table-column
            width="190"
            :label="$t('Редактировать')"
            class-name="element-top"
          >
            <template #default="scope">
              <el-button v-if="!scope.row.isEdit" size="small" @click="editTable(scope.row, scope.$index)">
                <i class="fa fa-edit"/>
              </el-button>
              <div v-else style="display: flex; justify-content: space-between; margin-bottom: 10px">
                <el-button size="small" @click="saveData(scope.row, scope)">
                  {{ $t('Сохранить') }}
                </el-button>
                <el-button size="small" @click="cancelEdit(scope.row, scope.$index)">
                  {{ $t('Отмена') }}
                </el-button>
              </div>
            </template>
          </el-table-column>

          <el-table-column
            width="190"
            :label="$t('Текущие сессии')"
            class-name="element-top"
          >
            <template #default="scope">
              <el-button v-if="!scope.row.isEdit" size="small" @click="goToSessions(scope.row)">
                {{ $t('Показать сессию') }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-auto-resizer>
    </el-form>
  </el-col>
</template>

<script>
import {ElNotification} from 'element-plus';
import CaseController from "../../controllers/case.controller";
import ProfileController from "../../controllers/profile.controller";
import Utils from '../../services/utils';
import { availableLanguages } from '@/i18n/i18n';

export default {
  name: 'UserSettings',
  data() {
    return {
      height: Math.max(
        document.documentElement.clientHeight - 250,
        window.innerHeight - 250 || 0
      ),
      users: [],
      caseController: null,
      allRoles: ['admin', 'analitik', 'konstruktor', 'kurator', 'user'],
      search: "",
      rules: {
        email: [
          {required: true, message: this.$t("Поле не может быть пустым"), trigger: 'change'},
          {type: 'email', message: this.$t('Введите корректный email'), trigger: 'blur'}
        ],
        country: {
          required: true, message: this.$t("Поле не может быть пустым"), trigger: 'change'
        },
        roles: {
          required: true, message: this.$t("Поле не может быть пустым"), trigger: 'change'
        },
        lang: {
          required: true, message: this.$t("Поле не может быть пустым"), trigger: 'change'
        },
      },
      form: {
        users: [],
      },
      profileController: null,
      countriesList: [],
      countryOptions: [],
      langOptions: availableLanguages,
      notification: {
        schemeEditUser: null,
        schemeEditUserError: null,
      },
      oldUser: {},
    }
  },
  watch: {
    search() {
      this.debounce()
    },
  },
  mounted() {
    this.caseController = new CaseController();
    this.profileController = new ProfileController()
    this.getUsersList();
    this.getCountries();
    this.notification.schemeEditUser = () => {
      ElNotification({
        title: this.$t('Выполнено'),
        type: 'success',
        duration: 3000,
        position: 'bottom-right',
        message: this.$t('Данные успешно отредактированы'),
      });
      this.notification.schemeEditUserError = () => {
        ElNotification({
          title: this.$t('Ошибка'),
          type: 'error',
          duration: 3000,
          position: 'bottom-right',
          message: this.$t('Ошибка во время изменения данных'),
        });
      };
    };
  },
  methods: {
    showNotification(obj) {
      this.notification[obj.name](obj.agrs);
    },

    getUsersList(search) {
      this.form.users = [];

      this.caseController.getAllUsers(search).then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const rawUser = response.data[i]
          let roles = [];
          let mainRole = 'user'
          for (let j = 0; j < rawUser.roles.length; j++) {
            if (rawUser.roles[j] === 'admin') {
              mainRole = 'admin'
            }
            roles.push(rawUser.roles[j])
          }
          let prettyUser = {
            username: rawUser.username,
            roles: roles,
            email: rawUser.email,
            password: '*******',
            country: rawUser.country[1],
            mainRole: mainRole,
            isEdit: false,
            userId: rawUser._id,
            lang: rawUser.lang
          }
          this.form.users.push(prettyUser)
        }
      })
    },

    editTable(row, index) {
      row.isEdit = !row.isEdit;
      this.oldUser = {...this.oldUser, [index]: {...row}};
    },

    saveData(row, scope) {
      let errors = [];
      this.$refs.form.validateField([
        `users.${scope.$index}.email`,
        `users.${scope.$index}.roles`,
        `users.${scope.$index}.country`,
        `users.${scope.$index}.lang`,
      ], (isValid, errorsField) => {
        if (errorsField) {
          errors.push(errorsField);
        }
      });
      if (!errors.length) {
        const country = this.countriesList.find((country) => country[1] === row.country)
        row.isEdit = !row.isEdit;
        const data = {
          email: row.email,
          country,
          roles: row.roles,
          lang: row.lang
        }
        this.profileController.editUserInfo(row.userId, data)
          .then(() => {
            this.showNotification({
              name: 'schemeEditUser',
              agrs: {text: this.$t('Данные успешно обновлены')},
            })
          }).catch(() =>
          this.showNotification({
            name: 'schemeEditUserError',
            agrs: {text: this.$t('Ошибка при создании папки')},
          }),
        );

        return true;
      } else {
        return false;
      }
    },
    debounce() {
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        this.getUsersList({search: this.search});
      }, 1000);
    },

    getCountries() {
      this.profileController.getCountries()
        .then((res) => {
          this.countriesList = res.data.data;
          this.countryOptions = res.data.data.map((country) => {
            return {
              value: country[1],
              label: country[1]
            }
          })
        });
    },
    cancelEdit(row, index) {
      if (!Utils.isEqual(row, this.oldUser)) {
        row = Object.assign(row, this.oldUser[index], {...this.oldUser[index]});
        delete this.oldUser[index];
      }
      this.$refs.form.clearValidate()
      row.isEdit = false;
    },
    goToSessions(row) {
      this.$router.push(`/sessions/${row.userId}`)
    },
  }
}
</script>

<style scoped>
.cell-custom {
  word-break: break-word !important;
}

.element-top {
  vertical-align: top !important;
}
</style>
